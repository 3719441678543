<template>
	<div id="about" class="row row-fluid">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer"></div>
						<div class="title">
							<h2 class="head-fancy mt-0">About <span>Villa Monte Kope</span></h2>
						</div>
						<div class="text-column content-element">
							<div class="wrapper" style="text-align: justify;">
								<p class="sub-text">On a fenced 929 sqm property, a 308 sqm modern villa, is named after small hill Monte Kope where it is located, at the entrance to the Cape Kamenjak. The combination of a modern design and warm colors has created a perfect home for your vacation. But, general sense of minimalism doesn’t prevent it from being very comfortable and elegant providing you with all you need for Your vacation days! </p>
							</div>
						</div>
						<div class="single-image">
							<figure class="wrapper-figure">
								<div class="image-wrapper">
									<img src="../assets/photos/about-canvas.jpg" alt="Villa Monte Kope Outside View" style="width: 100%; height: auto;">
								</div>
							</figure>
						</div>
						<div style="height: 40px; width: 100%; display: block;"></div>
						<div class="row inner-row">
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/bed-sm.png" alt="Bed Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>4</strong>
												<span>Bedrooms</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/bath-sm.png" alt="Bath Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>5</strong>
												<span>Bathrooms</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/water-sm.png" alt="Pool Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>45</strong>
												<span>Sq.M.</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/home-sm.png" alt="Sound system Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>Full</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/property-sm.png" alt="Car parking Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>4</strong>
												<span>Car parking</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-unit">
											<div class="icon-unit-icon">
												<i>
													<img src="../assets/icons/moneyBag-sm.png" alt="Deposit Icon">
												</i>
											</div>
											<div class="icon-unit-info">
												<strong>1000€</strong>
												<span>Deposit</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "about"
	}
</script>

<style>
	.icon-unit {
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 40px;
	}
	.icon-unit-icon {
		display: inline-block;
		width: 90px;
		height: 90px;
		border: 1px solid #ececec;
		position: relative;
	}
	.icon-unit-icon i {
		position: absolute;
		width: auto;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.icon-unit-info {
		display: inline-block;
		width: 100%;
		margin: 10px 0 0;
	}
	.icon-unit-info strong {
		display: block;
		font-size: 24px;
		color: #303030;
		font-weight: 700;
		margin-bottom: 7px;
	}
	.icon-unit-info span {
		display: block;
	}
	.icon-unit-icon:hover {
		background-color: #3f85ce;
	}
	.wrapper-figure, .image-wrapper {
		display: inline-block;
		vertical-align: top;
		margin: 0;
		max-width: 100%;
	}
	.single-image {
		text-align: center;
		margin-bottom: 0;
	}
	.sub-text {
		margin: 0 auto;
		text-align: justify;
		max-width: 762px;
	}
	.text-column :last-child {
		margin-bottom: 0;
	}
	.content-element {
		margin-bottom: 35px;
	}
	.title {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin: 0 0 35px;
		position: relative;
		z-index: 1;
	}
	.spacer {
		height: 80px;
	}
	.spacer-sm {
		height: 40px;
	}
	.head-fancy {
		line-height: normal;
		font-weight: 300;
		position: relative;
		padding: 0 0 23px;
		font-family: Raleway, sans-serif;
	}
	.head-fancy:before {
		content: '';
		display: inline-block;
		position: absolute;
		left: 50%;
		bottom: 7px;
		height: 2px;
		background-color: #3f85ce;
		width: 95px;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.head-fancy:after {
		content: '';
		display: inline-block;
		position: absolute;
		left: 50%;
		bottom: 0;
		height: 2px;
		background-color: #3f85ce;
		width: 95px;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
		margin-left: 40px;
	}
	.head-fancy span {
		font-weight: 900;
		font-family: Raleway-ExtraBold, sans-serif;
	}

	/********************************/
	/*      SPACER RESPOPNSIVE     */

	@media (max-width: 1200px) {
		.spacer {
			height:60px;
		}
	}
	@media (max-width: 991px) {
		.spacer {
			height:50px;
		}
	}
	@media (max-width: 767px) {
		.spacer {
			height:45px;
		}
	}
	@media (max-width: 574px) {
		.spacer {
			height:35px;
		}
	}

</style>
