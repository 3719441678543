import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        calendarData: [],
        bookingData: {},
        currentYear: ''
    },
    mutations: {
        setCalendarData (state, payload) {
            let saved = false;
            if (state.calendarData.length > 0) {
                state.calendarData.forEach(function(item) {
                    if (item.year === state.currentYear)  {
                        item.dateList = payload.dateList;
                        saved = true;
                    }
                })
                if (!saved) state.calendarData.push(payload)
            } else {
                state.calendarData.push(payload)
            }
        },
        setBookingData (state, payload) {
            state.bookingData = payload
        },
        setCurrentYear (state, payload) {
            state.currentYear = payload
        }
    },
    getters: {
        getCalendarData: state => {
            return state.calendarData.filter(item => item.year === state.currentYear)
        },
        getBookingData: state => {
            return state.bookingData
        }
    }
})
