<template>
	<div id="navigation">
		<div class="header-sidebar open trans-500ms" :style="{width: navWidth + 'px'}">
			<div class="sidebar-logo-top">
				<a v-if="toggleLogoSize" class="trans-500ms">
					<div class="bar-navv" @click="toggleLogoSize = !toggleLogoSize"><a><img src="../assets/logo/navIcon.png"></a></div>
				</a>
				<div v-if="!desktopView" class="bar-nav" @click="toggleLogoSize = !toggleLogoSize"></div>
				
				<div v-if="!toggleLogoSize" class="bar-nav" @click="toggleLogoSize = !toggleLogoSize"></div>

				<div v-if="!toggleLogoSize"><a href="https://www.villa-borna.eu" class="trans-500ms">
					<img src="../assets/logo/logo-bg.png" alt="Villa Monte Kope logo big">
				</a></div>
			</div>
			<div id="sideBarFullMenu" class="mCustomScrollbar sidebar-full-menu">
				<div class="mCustomScrollBox" style="max-height: none;">
					<div style="position: relative; top: 0; left: 0; direction: ltr;">
						<div class="one-page-menu-container">
							<ul class="nav navbar-nav">
								<li>
									<a href="#home" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/home-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/home-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Home</span>
									</a>
								</li>
								<li>
									<a href="#about" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/about-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/about-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">About Us</span>
									</a>
								</li>
								<li>
									<a href="#description" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/desc-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/desc-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Description</span>
									</a>
								</li>
								<li>
									<a href="#amenities" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/amenities-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/amenities-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Amenities</span>
									</a>
								</li>
								<li>
									<a href="#gallery" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/gallery-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/gallery-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Gallery</span>
									</a>
								</li>
								<li>
									<a href="#nearby" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/location-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/location-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Near By</span>
									</a>
								</li>
								<li>
									<a href="#availability" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/phone-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/phone-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Availability</span>
									</a>
								</li>
								<li>
									<a href="#blog" class="trans-500ms menu-image-title-after menu-image-hovered">
										<div class="side-menu-icon">
											<i>
												<img width="28" height="26" src="../assets/icons/quote-sm.png" alt="Home icon">
												<!--<img width="28" height="26" src="../assets/icons/quote-sm-w.png" alt="Home icon">-->
											</i>
										</div>
										<span v-if="!toggleLogoSize" class="trans-500ms">Blog</span>
									</a>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "navigation",
		data () {
			return {
				toggleLogoSize: true,
				desktopView: true,
				navWidth: 70
			}
		},
		created() {
			if (window.innerWidth < 768) {
				this.desktopView = false;
				this.navWidth = 1
			}
		},
		watch: {
			toggleLogoSize (state) {
				if (state) {
					if (this.desktopView) {
						this.navWidth = 70;
					} else {
						this.navWidth = 1
					}

				} else {
					this.navWidth = 290;
				}
			}
		}
	}
</script>

<style>
	.header-sidebar {
		width: 70px;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;

	}
	.bar-nav {
		position: absolute;
		right: -50px;
		top: 40px;
		background: #000 url("../assets/logo/navIcon.png") no-repeat center center;
		float: left;
		width: 50px;
		height: 50px;
		cursor: pointer;
	}
	.sidebar-logo-top {
		float: left;
		width: 100%;
		background: #000;
		text-align: center;
		padding: 30px 20px;
	}
	.sidebar-full-menu {
		position: relative;
		overflow: visible;
		float: left;
		width: 100%;
		height: 100%;
		background: #2c2c2c;
	}
	.side-menu-icon i {
		position: absolute;
		width: auto;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.sidebar-full-menu ul li {
		float: left;
		width: 100%;
		background: #212121;
		list-style: none;
	}
	.nav>li {
		position: relative;
		display: block;

	}
	.header-sidebar.open .sidebar-full-menu ul li a {
		padding-left: 110px;
		padding-right: 20px;
	}
	.sidebar-full-menu ul li a {
		float: left;
		width: 100%;
		padding: 20px 0 20px 70px;
		color: #ffffff;
		position: relative;
		border-bottom: 1px solid #000000;
		min-height: 70px;
		text-align: left;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 20px;
	}
	.sidebar-full-menu ul li:hover a, .sidebar-full-menu ul li.active a {
		background-color: #3f85ce;
	}
	.side-menu-icon {
		background-color: #2c2c2c;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		height: 100%;
		width: 70px;
	}
	.header-sidebar.open .sidebar-full-menu ul li a span {
		font-size: 16px;
		visibility: visible;
	}

	/*************************************/
	/*         RESPONSIVNES             */

	@media (min-width: 768px) {
		.navbar-nav>li>a {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.sidebar-full-menu ul li a {
			min-height: 70px;
		}
	}
	@media (max-width: 768px) {
		.sidebar-logo-top {
			padding: 50px 0;
		}
		.sidebar-full-menu ul li a {
			min-height: 50px;
		}
	}
</style>
