<template>
	<div id="home">
		<navigation></navigation>
		<div class="scroll-behaviour">
			<home-screen></home-screen>
			<about></about>
			<description></description>
			<div class="clearfix"></div>
			<div class="row-full-width row row-fluid add-button row-has-fill row-o-full-height row-o-columns-middle row-o-equal-height row-flex" style="margin-left: 0;">
					<div class="col-lg-9 col-sm-8">
						<div class="column-inner">
							<div class="wrapper">
								<div class="text-column content-element">
									<div class="wrapper">
										<div class="tour-inner">
											<div class="tour-inner-left">
												<h3>
													<b-icon class="tour-inner-icon" icon="house-fill" style="color: #fff;"></b-icon>
													Villa
													<strong>Monte Kope</strong>
												</h3>
											</div>
											<div class="tour-inner-right">
												<h5>Take a video tour</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-4">
						<div class="column-inner">
							<div class="wrapper">
								<div style="text-align: center;">
									<a href="https://www.youtube.com/watch?v=TcY8uqHz5Uw" class="btn-side" target="_blank" style="background-color: #ffffff; color: #212121 !important;">
										See video
										<span id="btn-span-1" style="font-size: 1.3rem;">
											<b-icon icon="play-fill" class="rounded-circle bg-dark p-1" variant="light"></b-icon>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
			</div>
			<amenities></amenities>
			<gallery></gallery>
			<nearBy></nearBy>
			<div class="row row-fluid row-has-fill row-no-padding module-divider"></div>
			<calendar></calendar>
			<blog></blog>
			<vb-footer></vb-footer>
		</div>
		<a href="#" class="top-arrow arrow-img"></a>
	</div>
</template>

<script>
	import navigation from '../components/navigation.vue'
	import homeScreen from '../components/homeScreen.vue'
	import about from '../components/about.vue'
	import description from '../components/description.vue'
	import amenities from '../components/amenities.vue'
	import gallery from '../components/gallery.vue'
	import nearBy from '../components/nearBy.vue'
	import calendar from '../components/calendar.vue'
	import blog from '../components/blog.vue'
	import vbFooter from '../components/vbFooter.vue'

	export default {
		name: 'Home',
		data () {
			return {
				wh: '',
				ww: ''
			}
		},
		created () {
			this.wh = window.innerHeight;
			this.ww = window.innerWidth;
		},
		components: {
			navigation,
			homeScreen,
			about,
			description,
			amenities,
			gallery,
			nearBy,
			calendar,
			blog,
			vbFooter
		}
	}
</script>
<style>
	.arrow-img {
		background-image: url("../assets/icons/arrow-top.png");
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
	}
	.top-arrow {
		position: fixed;
		bottom: 50px;
		width: 50px;
		height: 50px;
		right: 30px;
		display: none;
		z-index: 99;
	}
	.module-divider {
		height: 130px;
		background-color: #32393E;
	}
	.btn-side {
		display: inline-block;
		position: relative;
		width: auto;
		min-width: 215px;
		padding: 0 60px 0 20px;
		text-align: center;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		font-weight: 700;
		border: none;
		background: #3f85ce;
		color: #ffffff;
		text-decoration: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	.btn-side span {
		width: 50px;
		background-color: #5093d3;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		vertical-align: middle;
		font-size: 16px;
	}
	#btn-span-1 {
		color: #212121;
		background-color: #ececec;
	}
	.tour-inner {
		display: inline-block;
		width: auto;
		position: relative;
		bottom: -5px;
	}
	.tour-inner-left {
		display: table-cell;
		width: auto;
		vertical-align: middle;
		padding-right: 70px;
		position: relative;
		font-family: Raleway, sans-serif;
	}
	.tour-inner-left h3 {
		font-size: 30px;
		line-height: 24px;
		color: #fff;
		font-weight: 300;
		margin: 0;
		position: relative;
	}
	.tour-inner-left:after {
		position: absolute;
		right: 0;
		height: 100%;
		width: 1px;
		background: #fff;
		opacity: .8;
		content: "";
		top: 0;
	}
	.tour-inner-right {
		display: table-cell;
		width: auto;
		vertical-align: middle;
		padding-left: 70px;
		position: relative;
	}
	.tour-inner-right h5 {
		color: #fff;
		font-weight: 300;
		margin: 0;
		position: relative;
	}
	.tour-inner-icon {
		display: inline;
		font-size: 40px;
		font-weight: 700;
		margin-right: 15px;
		margin-top: 0 !important;
		position: relative;
	}
	.row-full-width {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		background-color: #3f85ce;
		padding: 50px 0;
	}
	#home {
		overflow-x: hidden;
		float: left;
		width: 100%;
	}
	.row-fluid {
		overflow: auto;
	}
	.mt-0 {
		margin-top: 0;
	}
	.trans-500ms {
		-webkit-transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
		transition: all 500ms ease;
	}


	/***********************************/
	/*               COL
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
	}
*/
	/***********************************/
	/*          RESPONSIVE            */

	@media screen and (min-width: 1230px) {
		h6 {
			font-size: 17px;
		}
	}
	@media screen and (min-width: 992px) {
		.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .colmd-10, .col-md-11, .col-md-12 {
			float: left;
		}
		.col-md-4 {
			width: 33.333333333%;
		}
	}

	@media screen and (min-width: 768px) {
		.col-sm-2 {
			width: 16.66666667%;
		}
		.col-sm-4 {
			width: 33.33333333%;
		}
		.col-sm-6 {
			width: 50%;
		}
		.col-sm-8 {
			width: 66.66666667%;
		}
		.col-sm-12 {
			width: 100%;
		}
		.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
			float: left;
		}
	}



</style>
