<template>
	<div id="hs" class="row row-fluid">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div v-if="desktopView" class="info-block responsive-block">
							
							
							<h1 style="color: #ffffff;"><img src="../assets/logo/logo-white.png" alt="Villa Monte Kope logo"></h1>
							<p style="color: #ffffff;">An ideal world. If only for a night.</p>
							<p style="text-align: center; margin: 0 0 10px;"><span class="divider-line" style="margin: 0;"></span></p>
							<div style="width: 100%; display: table; height: auto; padding: 0 15px;">
								<div class="info-block-icon-box">
									<div class="wrapper">
										<div class="icon-wrap-top">
											<img src="../assets/icons/person-sm-w.png" alt="bed icon">
										</div>
										<div class="icon-wrap-mid">
											<b>10</b>
										</div>
										<div class="icon-wrap-bot">
											<p>Persons</p>
										</div>
									</div>
								</div>
								<div class="info-block-icon-box">
									<div class="wrapper">
										<div class="icon-wrap-top">
											<img src="../assets/icons/bed-sm-w.png" alt="bed icon">
										</div>
										<div class="icon-wrap-mid">
											<b>4</b>
										</div>
										<div class="icon-wrap-bot">
											<p>Bedrooms</p>
										</div>
									</div>
								</div>
								<div class="info-block-icon-box">
									<div class="wrapper">
										<div class="icon-wrap-top">
											<img src="../assets/icons/bath-sm-w.png" alt="bed icon">
										</div>
										<div class="icon-wrap-mid">
											<b>5</b>
										</div>
										<div class="icon-wrap-bot">
											<p>Bathrooms</p>
										</div>
									</div>
								</div>
								<div class="info-block-icon-box">
									<div class="wrapper">
										<div class="icon-wrap-top">
											<img src="../assets/icons/resize-sm-w.png" alt="bed icon">
										</div>
										<div class="icon-wrap-mid">
											<b>308</b>
										</div>
										<div class="icon-wrap-bot">
											<p>Sq.m.</p>
										</div>
									</div>
								</div>
							</div>
							<p style="text-align: center; margin: 0 0 10px;"><span class="divider-line" style="margin: 0;"></span></p>
							<h2 style="color: #ffffff;">From</h2>
							<h3 style="color: #ffffff;">300€ / day</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="desktopView" class="icon-bottom-sd">
			<img src="../assets/icons/Scroll-Down.png" alt="scroll icon" style="width: 87px; height: 65px; margin: 0 auto;">
		</div>
		<div v-else class="info-block responsive-block">
			<div class="spacer-xs"></div>
			<h1 style="color: #ffffff;">Villa Monte Kope</h1>
			<p style="color: #ffffff;">An ideal world. If only for a night.</p>
			<p style="text-align: center; margin: 0 0 10px;"><span class="divider-line" style="margin: 0;"></span></p>
			<div style="width: 100%; display: table; height: auto; padding: 0 15px;">
				<div class="info-block-icon-box">
					<div class="wrapper">
						<div class="icon-wrap-top">
							<img src="../assets/icons/person-sm-w.png" alt="bed icon">
						</div>
						<div class="icon-wrap-mid">
							<b>10</b>
						</div>
						<div class="icon-wrap-bot">
							<p>Persons</p>
						</div>
					</div>
				</div>
				<div class="info-block-icon-box">
					<div class="wrapper">
						<div class="icon-wrap-top">
							<img src="../assets/icons/bed-sm-w.png" alt="bed icon">
						</div>
						<div class="icon-wrap-mid">
							<b>4</b>
						</div>
						<div class="icon-wrap-bot">
							<p>Bedrooms</p>
						</div>
					</div>
				</div>
				<div class="info-block-icon-box">
					<div class="wrapper">
						<div class="icon-wrap-top">
							<img src="../assets/icons/bath-sm-w.png" alt="bed icon">
						</div>
						<div class="icon-wrap-mid">
							<b>5</b>
						</div>
						<div class="icon-wrap-bot">
							<p>Bathrooms</p>
						</div>
					</div>
				</div>
				<div class="info-block-icon-box">
					<div class="wrapper">
						<div class="icon-wrap-top">
							<img src="../assets/icons/resize-sm-w.png" alt="bed icon">
						</div>
						<div class="icon-wrap-mid">
							<b>308</b>
						</div>
						<div class="icon-wrap-bot">
							<p>Sq.m.</p>
						</div>
					</div>
				</div>
			</div>
			<p style="text-align: center; margin: 0 0 10px;"><span class="divider-line" style="margin: 0;"></span></p>
			<h2 style="color: #ffffff;">From</h2>
			<h3 style="color: #ffffff;">300€ / day</h3>
			<div class="spacer-xs"></div><br>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'homeScreen',
		data () {
			return {
				desktopView: true,
				hs: '',
				wh: ''
			}
		},
		created () {
			if (window.innerWidth < 768) {
				this.desktopView = false;
			}
			this.wh = window.innerHeight;
		},
		mounted () {
			this.setScreenHeight();
		},
		methods: {
			setScreenHeight: function () {
				this.hs = document.querySelector('#hs');
				this.hs.style.height = this.wh + 'px';
			},
		}
	}
</script>

<style>
	.info-block-icon-box {
		margin: 0 auto;
		width: 25%;
		height: auto;
		display: inline-block;
		position: relative;
	}
	.icon-wrap-top {
		padding: 10px 0;
	}
	.spacer-xs {
		height: 15px;
	}
	.info-block {
		z-index: 6;
		background-color: rgb(33, 33, 33);
		visibility: visible;
		border-color: rgb(121, 121, 121);
		border-style: none;
		margin: 0;
		border-radius: 0;
		padding: 0;
		color: rgb(255, 255, 255);
		height: 520px;
		width: 355px;
		min-height: 0;
		min-width: 0;
		max-height: none;
		max-width: none;
		transform-origin: 50% 50%;
		opacity: 1;
		transform: perspective(600px);

	}
	#hs {
		overflow: hidden;
		width: 100%;
		position: relative;
		display: block;
		height: 680px;
		background-image: url("../assets/photos/home.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-left: 0;
		padding-top: 6%;
	}
	.icon-bottom-sd {
		position: absolute;
		bottom: 50px;
		left: 0;
		width: 100%;
		display: block;
		text-align: center;
	}
	.info-block h1, .info-block h2, .info-block h3 {
		margin: 0;
	}


	@media (min-width: 1230px) {
		.responsive-block {
			float: right;
			margin-right: -188px;
		}
	}
	@media (max-width: 972px) {
		.responsive-block {
			float: right;
			margin-right: -140px;
		}
	}
	@media (max-width: 1920px) {
		.responsive-block {
			float: right;
			margin-right: -190px;
		}
	}
	@media (max-width: 768px) {
		.info-block {
			width: 300px;
			height: 320px;
		}
		.info-block h1 {
			font-size: 25px;
		}
		.info-block h2 {
			font-size: 18px;
		}
		.info-block h3 {
			font-size: 20px;
		}
		.info-block p {
			font-size: 14px;
		}
		.icon-wrap-top {
			padding: 0;
		}
		#hs {
			background-position: top;
		}
		.responsive-block {
			float: none;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	@media (max-width: 576px) {
		.col-sm-2 {
			width: 33.333333333% !important;
			display: inline-block;
		}
		#gallery {
			padding-left: 10px;
		}
	}



</style>
