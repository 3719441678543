<template>
	<div id="nearby" class="row row-fluid vc_custom_1577426071892 row-has-fill">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer"></div>
						<div class="title">
							<h2 class="head-fancy mt-0">Near By <span>Places</span></h2>
						</div>
						<div class="text-column content-element">
							<div class="wrapper" style="text-align: justify;">
								<p>This fantastic property is situated in a well thought out, quiet and green residential area that mixes traditional Istrian flair with suburban and rural ambience. It is located only in a few minutes’ drive from some of the best beaches on the coast (some of them you can even access by foot), charming city of Pula and extraordinary nature.</p>
							</div>
						</div>
						<div class="place-map-row">
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>600 m</strong>
										<span>Beach</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>300 m</strong>
										<span>Restaurant</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>200 m</strong>
										<span>Bar</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>1 km</strong>
										<span>Shopping / groceries</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>7 km</strong>
										<span>Pula - Center</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>12 km</strong>
										<span>Pula Airport</span>
									</div>
								</div>
							</div>
							<div class="place-map-col">
								<div class="place-base-inner">
									<div class="place-base-info">
										<strong>18 km</strong>
										<span>NP Brijuni</span>
									</div>
								</div>
							</div>
						</div>
						<div class="spacer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "nearBy"
	}
</script>

<style>
	.place-map-row {
		margin: 0 -4px 25px;
	}
	.place-map-row:before, .place-map-row:after {
		clear: both;
		display: table;
		content: "";
	}
	.place-map-col {
		float: left;
		width: 14.28%;
		padding: 0 4px;
	}
	.place-base-inner {
		display: table;
		height: 130px;
		border: 1px solid #ececec;
		vertical-align: middle;
		width: 100%;
		background: #fff;
	}
	.place-base-info {
		display: table-cell;
		vertical-align: middle;
		height: 100%;
		padding: 0 20px 0 30px;
		color: #212121;
	}
	.place-base-info strong {
		display: block;
		font-weight: 700;
		font-size: 25px;
		margin-bottom: 3px;
	}
	.place-base-info span {
		display: block;
		font-size: 18px;
	}
	.place-map-col:hover .place-base-info {
		color: #ffffff;
	}
	.place-map-col:hover .place-base-inner {
		background-color: #3f85ce;
	}
	#nearby {
		background-color: #f9f9f9;
		width: 100%;
		position: relative;
		margin-right: 0;
	}

	@media (max-width: 576px) {
		.place-map-col {
			float: none;
			display: inline-block;
			width: 25%;
		}
		.place-base-info strong {
			font-size: 16px;
		}
		.place-base-info span {
			font-size: 13px;
		}
	}

</style>
