<template>
	<div id="footer" class="row row-fluid row-has-fill row-o-equal-height row-o-content-middle row-flex">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer"></div>
					</div>
				</div>
			</div>
			<div class="col-sm-5">
				<div class="column-inner">
					<div class="wrapper">
						<div class="text-column content-element">
							<div class="wrapper">
								<h3 style="color: #ffffff; text-align: left;">Get In Touch</h3>
								<p style="text-align: left;">
									<span class="divider-line"></span>
								</p>
								<ul class="social-att-list">
									<li style="padding-bottom: 15px;">
										<a href="mailto:info@villa-montekope.com" style="color: #ffffff;">
											<b-icon icon="envelope"></b-icon>
											info@villa-montekope.com
										</a>
									</li>
									<li style="padding-bottom: 15px;">
										<a href="https://www.facebook.com/villaborna.eu/" target="_blank" style="padding: 0;">
											<img src="../assets/icons/facebook.png" alt="Villa Borna Facebook Link">
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-1">
				<div class="column-inner">
					<div class="wrapper"></div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="column-inner">
					<div class="wrapper">
						<div id="map">
							<div class="image-wrapper-overlay">
								<a href="https://g.page/villa-borna?share" target="_blank" style="width: 100%; height: 100%;">
									<div class="image-wrapper-inner trans-500ms"></div>
								</a>
							</div>
						</div>
						<div class="text-column content-element">
							<div class="wrapper">
								<div class="address-print">
									<h5 style="color: #ffffff;">Our Address</h5>
									<p>Volme 53, 52100 Banjole, Istria County, Croatia
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="style-bottom">
			<div class="container">
				<div class="column-inner">
					<div class="wrapper">
						<div class="row">
							<div class="col-md-6 col-sm-6 col-xs-12 footer-style-copyleft">
								<div class="textwidget custom-html-widget">
									<span>© Copyright 2019 by <a href="https://www.h3.solutions" style="color: #ffffff;"><i style="font-size: 14px;">H.3 Solutions d.o.o.</i></a></span>
								</div>
							</div>
							<div class="col-md-6 col-sm-6 col-xs-12 footer-style-copyright">
								<div class="textwidget custom-html-widget">
									<ul>
										<li><a href="#home">Home</a></li>
										<li><a href="#about">About Us</a></li>
										<li><a href="#description">Description</a></li>
										<li><a href="#amenities">Amenities</a></li>
										<li><a href="#gallery">Our Gallery</a></li>
										<li><a href="#nearby">Near By</a></li>
										<li><a href="#availability">Availability</a></li>
										<li><a href="#blog">Our Blog</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "vb-footer"
	}
</script>

<style>
	#map {
		width: 100%;
		height: 500px;
		position: relative;
		overflow: hidden;
	}
	#footer {
		background-color: #32393E;
	}
	.social-att-list, .social-att-list li {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: left;
	}
	.social-att-list li {
		display: block;
		color: #fff;
	}
	.social-att-list li a {
		display: block;
		padding-left: 36px;
		position: relative;
		margin-bottom: 10px;
	}
	.social-att-list li a svg {
		position: absolute;
		left: 0;
		font-size: 17px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.address-print {
		position: absolute;
		right: 60%;
		top: 68px;
		max-width: 300px;
		width: 100%;
		background: #3f85ce;
		padding: 30px 37px;
		color: #fff;
		text-align: left;
	}
	.address-print h5 {
		color: #fff;
		border-left: 4px solid #fff;
		padding-left: 16px;
	}
	.address-print p {
		margin: 0;
	}
	.image-wrapper-overlay {
		width: 100%;
		height: 100%;
		display: block;
		margin: 0;
		padding: 0;
		border: none;
	}
	.image-wrapper-inner {
		height: inherit;
		min-height: inherit;
		max-height: inherit;
		width: inherit;
		background-image: url('../assets/photos/mapa.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		-o-transition: all .5s;
		transition: all .5s;
	}
	.image-wrapper-overlay:hover .image-wrapper-inner, .image-wrapper-overlay:focus .image-wrapper-inner {
		-ms-transform: scale(1.4);
		-moz-transform: scale(1.4);
		-webkit-transform: scale(1.4);
		-o-transform: scale(1.4);
		transform: scale(1.4);
	}
	.style-bottom {
		background: #1a1a1a;
		float: left;
		width: 100%;
		padding: 29px 0 25px;
		font-size: 14px;
		color: #d3d3d3;
		position: relative;
	}
	.footer-style-copyright ul {
		float: right;
		width: auto;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	.footer-style-copyright ul li {
		display: inline-block;
		width: auto;
	}
	.footer-style-copyright ul li a {
		display: block;
		color: #d3d3d3;
	}
	.footer-style-copyright li a:after {
		content: "|";
		display: inline-block;
		padding: 0 17px;
	}
	@media (max-width: 576px) {
		.address-print {
			right: unset;
			left: 0;
		}
	}

</style>
