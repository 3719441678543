<template>
	<div id="gallery" class="row row-fluid row-has-fill row-no-padding">
		<div class="col-sm-12">
			<div class="column-inner">
				<div class="wrapper">
					<div class="spacer-sm"></div>
					<div class="title">
						<h2 class="head-fancy mt-0" style="color: #ffffff;">Our <span>Gallery</span></h2>
					</div>
					<div class="spacer"></div>
					<div class="gallery-quick-inner">
						<carousel :nav="true" :navText="['&#10229;','&#10230;']" :autoplay="false" :loop="true" :dots="false" :responsive="{0:{items:1,nav:false},600:{items:3,nav:true}}">
							<img v-for="img in gallery" v-bind:key="img.id" :src="img.src" alt="img.alt">
						</carousel>
					</div>
					<div class="spacer-xs"></div>
					<div style="text-align: center;">
						<a id="gallery-btn" href="#gallery" class="btn-side" style="">
							View More
							<span>
								<b-icon icon="plus" font-scale="1" style="display: inline-block; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;"></b-icon>
							</span>
						</a>
					</div>
					<div class="spacer"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import carousel from 'vue-owl-carousel'

	import img1 from '../assets/photos/Villa_Borna_1.jpg'
	import img2 from '../assets/photos/Villa_Borna_2.jpg'
	import img3 from '../assets/photos/Villa_Borna_3.jpg'
	import img4 from '../assets/photos/Villa_Borna_4.jpg'
	import img5 from '../assets/photos/Villa_Borna_5.jpg'
	import img6 from '../assets/photos/Villa_Borna_6.jpg'
	import img7 from '../assets/photos/Villa_Borna_7.jpg'
	import img8 from '../assets/photos/Villa_Borna_8.jpg'
	import img9 from '../assets/photos/Villa_Borna_9.jpg'
	import img10 from '../assets/photos/Villa_Borna_10.jpg'
	import img11 from '../assets/photos/Villa_Borna_12.jpg'
	import img12 from '../assets/photos/Villa_Borna_12.jpg'
	import img13 from '../assets/photos/Villa_Borna_13.jpg'
	import img14 from '../assets/photos/Villa_Borna_14.jpg'
	import img15 from '../assets/photos/Villa_Borna_15.jpg'
	import img16 from '../assets/photos/Villa_Borna_16.jpg'
	import img17 from '../assets/photos/Villa_Borna_17.jpg'
	import img18 from '../assets/photos/Villa_Borna_18.jpg'
	import img19 from '../assets/photos/Villa_Borna_19.jpg'
	import img20 from '../assets/photos/Villa_Borna_20.jpg'
	import img21 from '../assets/photos/Villa_Borna_21.jpg'
	import img22 from '../assets/photos/Villa_Borna_22.jpg'
	import img23 from '../assets/photos/Villa_Borna_23.jpg'
	import img24 from '../assets/photos/Villa_Borna_24.jpg'
	import img25 from '../assets/photos/Villa_Borna_25.jpg'
	import img26 from '../assets/photos/Villa_Borna_26.jpg'
	import img27 from '../assets/photos/Villa_Borna_27.jpg'
	import img28 from '../assets/photos/Villa_Borna_28.jpg'
	import img29 from '../assets/photos/Villa_Borna_29.jpg'
	import img30 from '../assets/photos/Villa_Borna_30.jpg'
	import img31 from '../assets/photos/Villa_Borna_31.jpg'
	import img32 from '../assets/photos/Villa_Borna_32.jpg'
	import img33 from '../assets/photos/Villa_Borna_33.jpg'
	import img34 from '../assets/photos/Villa_Borna_34.jpg'
	import img35 from '../assets/photos/Villa_Borna_35.jpg'
	import img36 from '../assets/photos/Villa_Borna_36.jpg'
	import img37 from '../assets/photos/Villa_Borna_37.jpg'
	import img38 from '../assets/photos/Villa_Borna_38.jpg'
	import img39 from '../assets/photos/Villa_Borna_39.jpg'
	import img40 from '../assets/photos/Villa_Borna_40.jpg'
	import img41 from '../assets/photos/Villa_Borna_41.jpg'
	import img42 from '../assets/photos/Villa_Borna_42.jpg'
	import img43 from '../assets/photos/Villa_Borna_43.jpg'
	import img44 from '../assets/photos/Villa_Borna_44.jpg'
	import img45 from '../assets/photos/Villa_Borna_45.jpg'
	import img46 from '../assets/photos/Villa_Borna_46.jpg'
	import img47 from '../assets/photos/Villa_Borna_47.jpg'
	import img48 from '../assets/photos/Villa_Borna_48.jpg'
	import img49 from '../assets/photos/Villa_Borna_49.jpg'
	import img50 from '../assets/photos/Villa_Borna_50.jpg'
	import img51 from '../assets/photos/Villa_Borna_51.jpg'
	import img52 from '../assets/photos/Villa_Borna_52.jpg'
	import img53 from '../assets/photos/Villa_Borna_53.jpg'
	import img54 from '../assets/photos/Villa_Borna_54.jpg'
	import img55 from '../assets/photos/Villa_Borna_55.jpg'
	import img56 from '../assets/photos/Villa_Borna_56.jpg'
	import img57 from '../assets/photos/Villa_Borna_57.jpg'
	import img58 from '../assets/photos/Villa_Borna_58.jpg'
	import img59 from '../assets/photos/Villa_Borna_59.jpg'
	import img60 from '../assets/photos/Villa_Borna_60.jpg'
	import img61 from '../assets/photos/Villa_Borna_61.jpg'
	import img62 from '../assets/photos/Villa_Borna_62.jpg'
	import img63 from '../assets/photos/Villa_Borna_63.jpg'
	import img64 from '../assets/photos/Villa_Borna_64.jpg'
	import img65 from '../assets/photos/Villa_Borna_65.jpg'
	import img66 from '../assets/photos/Villa_Borna_66.jpg'
	import img67 from '../assets/photos/Villa_Borna_67.jpg'
	import img68 from '../assets/photos/Villa_Borna_68.jpg'

	export default {
		name: "gallery",
		data () {
			return {
				gallery: [
					{id: 1, src: img1, alt: 'Villa Borna photo 1'},
					{id: 2, src: img2, alt: 'Villa Borna photo 2'},
					{id: 3, src: img3, alt: 'Villa Borna photo 3'},
					{id: 4, src: img4, alt: 'Villa Borna photo 4'},
					{id: 5, src: img5, alt: 'Villa Borna photo 5'},
					{id: 6, src: img6, alt: 'Villa Borna photo 6'},
					{id: 7, src: img7, alt: 'Villa Borna photo 7'},
					{id: 8, src: img8, alt: 'Villa Borna photo 8'},
					{id: 9, src: img9, alt: 'Villa Borna photo 9'},
					{id: 10, src: img10, alt: 'Villa Borna photo 10'},
					{id: 11, src: img11, alt: 'Villa Borna photo 11'},
					{id: 12, src: img12, alt: 'Villa Borna photo 12'},
					{id: 13, src: img13, alt: 'Villa Borna photo 13'},
					{id: 14, src: img14, alt: 'Villa Borna photo 14'},
					{id: 15, src: img15, alt: 'Villa Borna photo 15'},
					{id: 16, src: img16, alt: 'Villa Borna photo 16'},
					{id: 17, src: img17, alt: 'Villa Borna photo 17'},
					{id: 18, src: img18, alt: 'Villa Borna photo 18'},
					{id: 19, src: img19, alt: 'Villa Borna photo 19'},
					{id: 20, src: img20, alt: 'Villa Borna photo 20'},
					{id: 21, src: img21, alt: 'Villa Borna photo 21'},
					{id: 22, src: img22, alt: 'Villa Borna photo 22'},
					{id: 23, src: img23, alt: 'Villa Borna photo 23'},
					{id: 24, src: img24, alt: 'Villa Borna photo 24'},
					{id: 25, src: img25, alt: 'Villa Borna photo 25'},
					{id: 26, src: img26, alt: 'Villa Borna photo 26'},
					{id: 27, src: img27, alt: 'Villa Borna photo 27'},
					{id: 28, src: img28, alt: 'Villa Borna photo 28'},
					{id: 29, src: img29, alt: 'Villa Borna photo 29'},
					{id: 30, src: img30, alt: 'Villa Borna photo 30'},
					{id: 31, src: img31, alt: 'Villa Borna photo 31'},
					{id: 32, src: img32, alt: 'Villa Borna photo 32'},
					{id: 33, src: img33, alt: 'Villa Borna photo 33'},
					{id: 34, src: img34, alt: 'Villa Borna photo 34'},
					{id: 35, src: img35, alt: 'Villa Borna photo 35'},
					{id: 36, src: img36, alt: 'Villa Borna photo 36'},
					{id: 37, src: img37, alt: 'Villa Borna photo 37'},
					{id: 38, src: img38, alt: 'Villa Borna photo 38'},
					{id: 39, src: img39, alt: 'Villa Borna photo 39'},
					{id: 40, src: img40, alt: 'Villa Borna photo 40'},
					{id: 41, src: img41, alt: 'Villa Borna photo 41'},
					{id: 42, src: img42, alt: 'Villa Borna photo 42'},
					{id: 43, src: img43, alt: 'Villa Borna photo 43'},
					{id: 44, src: img44, alt: 'Villa Borna photo 44'},
					{id: 45, src: img45, alt: 'Villa Borna photo 45'},
					{id: 46, src: img46, alt: 'Villa Borna photo 46'},
					{id: 47, src: img47, alt: 'Villa Borna photo 47'},
					{id: 48, src: img48, alt: 'Villa Borna photo 48'},
					{id: 49, src: img49, alt: 'Villa Borna photo 49'},
					{id: 50, src: img50, alt: 'Villa Borna photo 50'},
					{id: 51, src: img51, alt: 'Villa Borna photo 51'},
					{id: 52, src: img52, alt: 'Villa Borna photo 52'},
					{id: 53, src: img53, alt: 'Villa Borna photo 53'},
					{id: 54, src: img54, alt: 'Villa Borna photo 54'},
					{id: 55, src: img55, alt: 'Villa Borna photo 55'},
					{id: 56, src: img56, alt: 'Villa Borna photo 56'},
					{id: 57, src: img57, alt: 'Villa Borna photo 57'},
					{id: 58, src: img58, alt: 'Villa Borna photo 58'},
					{id: 59, src: img59, alt: 'Villa Borna photo 59'},
					{id: 60, src: img60, alt: 'Villa Borna photo 60'},
					{id: 61, src: img61, alt: 'Villa Borna photo 61'},
					{id: 62, src: img62, alt: 'Villa Borna photo 62'},
					{id: 63, src: img63, alt: 'Villa Borna photo 63'},
					{id: 64, src: img64, alt: 'Villa Borna photo 64'},
					{id: 65, src: img65, alt: 'Villa Borna photo 65'},
					{id: 66, src: img66, alt: 'Villa Borna photo 66'},
					{id: 67, src: img67, alt: 'Villa Borna photo 67'},
					{id: 68, src: img68, alt: 'Villa Borna photo 68'},
				]
			}
		},
		components: {
			carousel
		}
	}
</script>

<style>
	#gallery {
		padding-left: 70px;
		background-color: #030303;
	}
	.gallery-quick-inner {
		display: inline-block;
		width: 100%;
		height: 475px;
	}
	#gallery-btn:hover {
		color: #ffffff;
	}
	.owl-theme .owl-nav {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
        line-height: 18px;
	}
	.owl-nav .owl-prev {
		width: 50px;
		height: 50px;
		float: left;
		margin-left: 50px !important;
		padding: 15px 7px !important;
		background-color: #3f85ce!important;
		color: #ffffff !important;
	}
	.owl-nav .owl-next {
		width: 50px;
		height: 50px;
		padding: 15px 7px !important;
		float: right;
		margin-right: 50px !important;
		background-color: #3f85ce !important;
		color: #ffffff !important;
	}
	@media (max-width: 576px) {
		#gallery {
			padding-left: 10px;
		}
	}

</style>
