<template>
	<div id="description" class="row row-fluid row-has-fill">
		<div class="container" style="margin: 0 auto;">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer"></div>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="column-inner" style="position: relative;">
					<div class="wrapper">
						<div class="single-image">
							<figure class="wrapper-figure">
								<div class="image-wrapper" style="width: 370px; height: 660px;">
									<div class="bg-img"></div>
								</div>
							</figure>
						</div>
						<div>
							<a id="highlight-drag-left" href="#gallery" class="btn-side">
								View More
								<span>
								<b-icon icon="box-arrow-in-right" style="display: inline-block; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;"></b-icon>
							</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-8">
				<div class="column-inner">
					<div class="wrapper">
						<div class="text-column content-element">
							<div class="wrapper" style="text-align: left;">
								<h2 class="head-sub">Description</h2>
								<p>
									<span class="divider-line"></span>
								</p>
							</div>
						</div>
						<div class="text-column content-element">
							<div class="wrapper" style="text-align: justify;">
								<p>The interior of the villa is elegantly furnished and equipped to the finest details. On the ground floor there is one en-suite bedroom, a fully equipped open concept kitchen with kitchen island and big, spacious living area, from which you can step on the covered outside kitchen and dining area, just next to the pool, looking at beautiful fenced garden with olive trees. Still on the ground floor there is a is a multimedia room where you can play video-games or enjoy in your favourite movie, with option to watch it even from the pool.  On the upper floor there is a play room with billiard table and 3 air-conditioned bedrooms, each with a en-suite bathroom for the privacy of the tenants. All rooms are connected with a balcony which extends to a terrace with a spacious hi-tech whirlpool. You can relax here in massage with over 100 jets (hydro and air) and ambient of 40 lights and your favourite music from speakers with subwoofer. 
									In the sorrounded yard you and especially your younger ones can enjoy in a sling and kids playground with a slide and several creepers where they can climb in search for the seaview. For those than enjoy in sports house is equipped with exterior exercise polygon, table tennis and bicycles. 
									Villa’s location at the entrance to Cape Kamenjak allows short trips and adventures in this beautifully protected area of nature. Here you can enjoy clean sea, sun, gorgious bays and beaches, fresh air, beautiful environment and soothing noises of nature. The position at the southernmost tip and most maritime part of Istria, this magical Croatian region, allows you to visit the beautiful Brijuni National Park, UNESCO heritage in Porec, the Roman amphitheater in Pula and many other fairytale places, where you can experience the ultimate gastronomic delights of the Mediterranean cuisine.
								</p>
							</div>
						</div>
						<div class="space" style="height: 10px; width: 100%;"></div>
						<div class="row row-fluid">
							<div class="col-sm-6">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-wrap">
											<div class="icon-left">
												<div class="icon-book">
													<b-icon icon="chat-quote"></b-icon>
												</div>
											</div>
											<div class="icon-right">
												<h6>Banjole Neighborhood</h6>
												<span>This fantastic property is situated in a well thought out, quiet and green residential area that mixes traditional Istrian flair with suburban and rural ambiance.</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-wrap">
											<div class="icon-left">
												<div class="icon-book">
													<b-icon icon="chat-quote"></b-icon>
												</div>
											</div>
											<div class="icon-right">
												<h6>Smart house</h6>
												<span>Villa Monte Kope is a 'smart' house but the technology is not overwhelming and has been designed for ease of use. All the equipment is controlled with a tablet.</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row row-fluid">
							<div class="col-sm-6">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-wrap">
											<div class="icon-left">
												<div class="icon-book">
													<b-icon icon="chat-quote"></b-icon>
												</div>
											</div>
											<div class="icon-right">
												<h6>Private heated pool</h6>
												<span>Private heated pool is perfect for enjoying long sunny days. It is equipped with LED lighting, containing 8 sun-beds and 4 parasols.</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="column-inner">
									<div class="wrapper">
										<div class="icon-wrap">
											<div class="icon-left">
												<div class="icon-book">
													<b-icon icon="chat-quote"></b-icon>
												</div>
											</div>
											<div class="icon-right">
												<h6>Awesome amenities</h6>
												<span>Whirlpool, video-game console, billiard, table tennis, bicycles and kids playground with sling, slide, climbers and creepers are offering entertainment for all ages. </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="spacer" style="height: 90px;"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "description"
	}
</script>

<style>
	.bg-img {
		width: 100%;
		height: 100%;
		display: block;
		background-image: url("../assets/photos/description.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	#description {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		background-color: #f9f9f9;
		margin-left: 0;
	}
	.icon-right h6 {
		margin-bottom: 10px;
	}
	.icon-book {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		line-height: 0;
	}
	.icon-wrap {
		float: left;
		width: 100%;
		margin: 40px 0 0;
	}
	.icon-left {
		float: left;
		width: 70px;
		height: 70px;
		padding: 15px;
		position: relative;
		border: 1px solid #ececec;
		color: #3f85ce;
		font-size: 20px;
	}
	.icon-right	{
		margin-left: 90px;
		text-align: left;
	}

	#highlight-drag-left {
		position: absolute;
		bottom: 12%;
		right: 48%;
	}
	#highlight-drag-left:hover {
		color: #ffffff;
	}
	.head-sub {
		font-family: Raleway, sans-serif;
		line-height: normal;
		font-weight: 300;
		position: relative;
		margin-bottom: 10px;
	}
	.divider-line {
		display: inline-block;
		width: 96px;
		height: 2px;
		background-color: #3f85ce;
		margin: 0 0 15px;
	}

</style>
