<template>
	<div id="blog" class="row row-fluid">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer-sm"></div>
						<div class="title">
							<h2 class="head-fancy mt-0">Our<span>Blog</span></h2>
						</div>
						<div class="spacer"></div>
						<div class="home-blog-list">
							<!--
							<carousel :nav="false" :autoplay="true" :loop="true" autoplaySpeed="500" :responsive="{0:{items:1,nav:false},600:{items:3,nav:true}}">
								<div class="item" v-for="item in items" v-bind:key="item.id"><h4>{{ item.message }}</h4></div>
							</carousel>
							-->
						</div>

						<div class="spacer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//import carousel from 'vue-owl-carousel'

	export default {
		name: "blog",
		data () {
			return {
				items: [
					{ message: 'Foo', id: '1' },
					{ message: 'Bar', id: '2' },
					{ message: 'Bar2', id: '3' },
					{ message: 'Bar3', id: '4' },
				],
			}
		},
		components: {
			//carousel
		}
	}
</script>

<style>

</style>
